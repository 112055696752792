import Link from 'next/link'

import { MENU_STYLES } from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'

interface NavBarLinkLevel3Props extends React.ComponentPropsWithoutRef<'a'> {
    href: string
}

export function NavBarLinkLevel3({ href, ...props }: NavBarLinkLevel3Props) {
    return (
        <Link href={href}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
                {...props}
                className={twMerge(
                    MENU_STYLES,
                    'block p-2 -ml-2 hover:text-blue-500 focus-visible:text-blue-500 transition-colors rounded-[0.7rem] lg:font-bold lg:py-[0.3rem]',
                    props.className
                )}
            />
        </Link>
    )
}
