import { BlogNaviIcon, FeaturesNaviIcon, PlanNaviIcon, SolutionsNaviIcon } from 'src/libs/icons'

import { NavBarLinkLevel2 } from './nav-bar-link/nav-bar-link-level-2'
import { NavBarLinkLevel3 } from './nav-bar-link/nav-bar-link-level-3'

interface MenuItemLevel1 {
    label: string
    description?: string
    elements: (MenuItemLevel2 | MenuItemLevel2WithoutHref)[]
}
interface MenuLevel2Static {
    label: string
    description?: string
    icon: React.ElementType<{ className?: string }>
}
interface MenuItemLevel2 extends MenuLevel2Static {
    href: string
    elements?: MenuItemLevel3[]
}
interface MenuItemLevel2WithoutHref extends MenuLevel2Static {
    href?: undefined
    elements: MenuItemLevel3[]
}
interface MenuItemLevel3 {
    label: string
    href: string
}

export const SOLUTIONS_ITEMS: MenuItemLevel3[] = [
    {
        label: 'Team Leads',
        href: '/solutions/team-leads',
    },
    {
        label: 'Full Cycle Sales',
        href: '/solutions/full-cycle-sales',
    },
    {
        label: 'SDRs / BDRs',
        href: '/solutions/sdrs-bdrs',
    },
    {
        label: 'Customer Success',
        href: '/solutions/customer-success',
    },
    {
        label: 'Sales Operations',
        href: '/solutions/sales-operations',
    },
    {
        label: 'Account Executives',
        href: '/solutions/account-executives',
    },
]

export const PRODUCT_MENU_ITEM: MenuItemLevel1 = {
    label: 'Product',
    description: 'Learn to love your new Sales Life and why people use Laserfocus.',
    elements: [
        {
            icon: FeaturesNaviIcon,
            label: 'Features',
            description: 'Flexible, intuitive features for any sales pipeline.',
            href: '/features',
        },
        {
            icon: SolutionsNaviIcon,
            label: 'Solutions',
            description: 'Explore how thought leaders are using Laserfocus.',
            elements: SOLUTIONS_ITEMS,
        },
    ],
}

const RESOURCES_MENU_ITEM: MenuItemLevel1 = {
    label: 'Resources',
    description: 'More in-depth information about every corner of the Laserfocus deal pipeline.',
    elements: [
        {
            icon: BlogNaviIcon,
            label: 'Blog',
            description: 'Flexible, intuitive features for any sales pipeline.',
            href: '/blog',
        },
    ],
}

export const MOBILE_MENU_ITEMS: MenuItemLevel1[] = [
    PRODUCT_MENU_ITEM,
    {
        label: 'Pricing',
        elements: [
            {
                icon: PlanNaviIcon,
                label: 'Choose your plan',
                href: '/pricing',
            },
        ],
    },
    RESOURCES_MENU_ITEM,
]

export function NavBarMenuItemLevel2({
    elements,
    ...props
}: MenuItemLevel2 | MenuItemLevel2WithoutHref) {
    return (
        <div>
            <NavBarLinkLevel2 {...props} />
            {elements && elements.length > 0 && (
                <div className="pl-8">
                    {elements.map(({ label: level3Label, href: level3Href }, level3Index) => (
                        <NavBarLinkLevel3 key={level3Index} href={level3Href}>
                            {level3Label}
                        </NavBarLinkLevel3>
                    ))}
                </div>
            )}
        </div>
    )
}
