import Link from 'next/link'

import { twMerge } from 'src/libs/tailwind-css'

interface NavBarLinkLevel2Props {
    icon: React.ElementType<{ className?: string }>
    href?: string
    label: string
    description?: string
}

export function NavBarLinkLevel2({ icon: Icon, href, label, description }: NavBarLinkLevel2Props) {
    const sharedClassName =
        'group p-2 rounded-[0.7rem] font-bold text-[0.9rem] lg:text-[0.8rem] leading-[1.2] grid grid-flow-col grid-cols-[auto,minmax(0,1fr)] gap-2'

    if (href) {
        return (
            <Link href={href}>
                <a
                    className={twMerge(
                        sharedClassName,
                        'hover:bg-blue-100 hover:text-blue-500 focus-visible:text-blue-500 transition-colors'
                    )}
                >
                    <Icon className="w-4 h-4" />
                    <span className="grid gap-1">
                        {label}
                        {description && (
                            <span className="leading-normal font-normal text-grey-700/60 hidden lg:block group-hover:text-blue-500 transition-colors">
                                {description}
                            </span>
                        )}
                    </span>
                </a>
            </Link>
        )
    }

    return (
        <div className={sharedClassName}>
            <Icon className="w-4 h-4" />
            <span className="grid gap-1">
                {label}
                <span className="leading-normal font-normal text-grey-700/60 hidden lg:block">
                    {description}
                </span>
            </span>
        </div>
    )
}
