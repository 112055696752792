import Link from 'next/link'

import { CONTENT_CONTAINER_STYLES, HEADING_6_STYLES, MENU_STYLES } from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'

import { SOLUTIONS_ITEMS } from '../nav-bar/menu-items'

const FOOTER_LINKS = [
    {
        title: 'Product',
        links: [
            { label: 'Features', href: '/features' },
            { label: 'Status', href: 'https://status.laserfocus.io' },
            { label: 'Security', href: 'https://security.laserfocus.io' },
        ],
    },
    {
        title: 'Solutions',
        links: SOLUTIONS_ITEMS,
    },
    {
        title: 'Legal',
        links: [
            { label: 'Terms of Service', href: '/terms-of-service' },
            { label: 'Privacy Policy', href: '/privacy-policy' },
        ],
    },
]

interface FooterProps {
    className?: string
}

export function Footer({ className }: FooterProps) {
    return (
        <footer
            className={twMerge(
                CONTENT_CONTAINER_STYLES,
                'pt-32 pb-16 md:pt-40 grid gap-16',
                className
            )}
        >
            <div
                className={twMerge(
                    MENU_STYLES,
                    'grid gap-12 sm:grid-flow-col items-start sm:justify-between'
                )}
            >
                {FOOTER_LINKS.map(({ title, links }) => {
                    const hasSingleColumnLayout = links.length < 4
                    const linksIndexHalf = Math.ceil(links.length / 2)

                    return (
                        <div key={title} className="grid gap-6">
                            <h2 className={HEADING_6_STYLES}>{title}</h2>
                            {hasSingleColumnLayout ? (
                                <ul className="grid gap-4">
                                    {links.map(({ label, href }) => (
                                        <li key={label}>
                                            <FooterLink label={label} href={href} />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <ul className="grid gap-4 grid-flow-col-dense grid-cols-2 sm:grid-cols-1 xl:gap-x-16">
                                    {links.map(({ label, href }, index) => (
                                        <li
                                            key={label}
                                            className={
                                                index < linksIndexHalf
                                                    ? 'col-start-1'
                                                    : 'col-start-2 sm:col-start-1 xl:col-start-2'
                                            }
                                        >
                                            <FooterLink label={label} href={href} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )
                })}
            </div>
            <div className={twMerge(MENU_STYLES, 'text-grey-700/40')}>
                All rights reserved © Laserfocus Inc. {new Date().getFullYear()}
            </div>
        </footer>
    )
}

interface FooterLinkProps {
    label: string
    href: string
}

function FooterLink({ label, href }: FooterLinkProps) {
    const isExternalLink = /^https?:\/\//.test(href)

    return (
        <Link href={href}>
            <a target={isExternalLink ? '_blank' : undefined} className="hover:underline">
                {label}
            </a>
        </Link>
    )
}
