import { HEADING_4_STYLES, PARAGRAPH_SMALL_STYLES } from 'src/libs/design-system'
import { SwitchTransition } from 'src/libs/react-utils'

import { NavBarMenuItemLevel2, PRODUCT_MENU_ITEM } from './menu-items'

interface DesktopMenuProps {
    menuState: 'closed' | 'mobile' | 'product'
}

export function DesktopMenu({ menuState }: DesktopMenuProps) {
    return (
        <SwitchTransition
            enter="transition duration-100"
            enterFrom="opacity-0 scale-90 -translate-y-1"
            enterTo="opacity-1 scale-100 translate-y-0"
            leave="transition duration-100"
            leaveFrom="opacity-1 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-90 -translate-y-1"
            // will-change-transform: Prevents menu unmounting without transition sometines in Safari due to internal browser delay
            className="will-change-transform"
        >
            {menuState === 'product' && <DesktopMenuInner key={menuState} menuState={menuState} />}
        </SwitchTransition>
    )
}

interface DesktopMenuInnerProps {
    menuState: 'product'
}

function DesktopMenuInner({ menuState }: DesktopMenuInnerProps) {
    const { label, description, elements } = {
        product: PRODUCT_MENU_ITEM,
    }[menuState]

    return (
        <div className="absolute px-4 w-full -mt-2">
            <div
                className="w-full max-w-3xl mx-auto p-10 bg-white rounded-[1.6rem] grid grid-flow-col auto-cols-fr gap-8 items-start"
                style={{
                    boxShadow:
                        '0px 24px 156px rgba(123, 137, 167, 0.08), 0px 10px 65.17px rgba(123, 137, 167, 0.05), 0px 5.36px 34.84px rgba(123, 137, 167, 0.0477), 0px 3px 19.5px rgba(123, 137, 167, 0.04), 0px 1.60px 10.37px rgba(123, 137, 167, 0.0323), 0px 0.66px 4.32px rgba(123, 137, 167, 0.022)',
                }}
                onClick={(event) => event.stopPropagation()}
            >
                <div className="grid gap-[0.6rem]">
                    <h2 className={HEADING_4_STYLES}>{label}</h2>
                    {description && <p className={PARAGRAPH_SMALL_STYLES}>{description}</p>}
                </div>
                {elements.map((element, index) => (
                    <NavBarMenuItemLevel2 key={index} {...element} />
                ))}
            </div>
        </div>
    )
}
