import clsx from 'clsx'
import Link from 'next/link'

import { MENU_STYLES } from 'src/libs/design-system'
import { ArrowDownOutlinedIcon } from 'src/libs/icons'
import { twMerge } from 'src/libs/tailwind-css'

interface NavBarLinkLevel1Props extends React.ComponentPropsWithoutRef<'a'> {
    href: string
}

export function NavBarLinkLevel1({ href, className, ...props }: NavBarLinkLevel1Props) {
    return (
        <Link href={href}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a {...props} className={getClassName(className)} />
        </Link>
    )
}

interface NavBarButtonLevel1Props extends React.ComponentPropsWithoutRef<'button'> {
    isOpen: boolean
}

export function NavBarButtonLevel1({
    className,
    children,
    isOpen,
    ...props
}: NavBarButtonLevel1Props) {
    return (
        <button
            {...props}
            className={getClassName(clsx('grid grid-flow-col gap-1 items-center', className))}
        >
            {children}
            <ArrowDownOutlinedIcon className="w-[0.7rem] h-[0.7rem]" />
        </button>
    )
}

function getClassName(className: string | undefined) {
    return twMerge(
        MENU_STYLES,
        'inline-block py-2 px-[0.8rem] rounded-[0.7rem] hover:bg-blue-100 hover:text-blue-500 focus-visible:text-blue-500 transition-colors duration-300',
        className
    )
}
