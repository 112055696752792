import { NavBar } from '../nav-bar/nav-bar'
import { Footer } from '../footer/footer'

interface PageLayoutProps {
    isNavBarFixed?: boolean
    children: React.ReactNode
}

export function PageLayout({ isNavBarFixed, children }: PageLayoutProps) {
    return (
        <div className="min-h-screen grid grid-rows-[auto,auto,minmax(0,1fr)]">
            <NavBar isFixed={isNavBarFixed} />
            <main className="row-start-3">{children}</main>
            <Footer className="row-start-4" />
        </div>
    )
}
