import { useEffect, useState } from 'react'

import { CONTENT_CONTAINER_STYLES, HEADING_6_STYLES, LinkButton } from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'

import { MOBILE_MENU_ITEMS, NavBarMenuItemLevel2 } from './menu-items'
import { NavBarLinkLevel1 } from './nav-bar-link/nav-bar-link-level-1'

export const MOBILE_MENU_ID = 'lf-nav-bar-mobile-menu'

interface MobileMenuProps {
    isOpen: boolean
}

const MENU_CLOSE_TRANSITION_TIMEOUT = 1000

export function MobileMenu({ isOpen }: MobileMenuProps) {
    const [shouldShowMenu, setShouldShowMenu] = useState(false)

    useEffect(() => {
        if (!isOpen) {
            const timeout = window.setTimeout(
                () => setShouldShowMenu(false),
                MENU_CLOSE_TRANSITION_TIMEOUT
            )
            return () => window.clearTimeout(timeout)
        }

        setShouldShowMenu(true)
    }, [isOpen])

    if (!isOpen && !shouldShowMenu) {
        return null
    }

    return (
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
            <div className="h-screen pt-12 md:pt-16 grid grid-rows-1" id={MOBILE_MENU_ID}>
                <div className={CONTENT_CONTAINER_STYLES}>
                    <div className="py-6 overflow-y-auto px-1 -mx-1 h-full overscroll-contain">
                        <div className="grid gap-6">
                            {MOBILE_MENU_ITEMS.map(
                                ({ label: level1Label, elements: level2Elements }, level1Index) => (
                                    <div key={level1Index} className="grid gap-[0.6rem]">
                                        <h2 className={twMerge(HEADING_6_STYLES, 'pl-2')}>
                                            {level1Label}
                                        </h2>
                                        <div className="grid gap-1">
                                            {level2Elements.map((level2Element, level2Index) => (
                                                <NavBarMenuItemLevel2
                                                    key={level2Index}
                                                    {...level2Element}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="py-4 border-t border-grey-700/10">
                    <div
                        className={twMerge(
                            CONTENT_CONTAINER_STYLES,
                            'grid grid-flow-col gap-2 justify-start'
                        )}
                    >
                        <LinkButton
                            variant="primary"
                            href="https://app.laserfocus.io/signup"
                            arrowRight
                        >
                            Get started for free
                        </LinkButton>
                        <NavBarLinkLevel1 href="https://app.laserfocus.io">Log in</NavBarLinkLevel1>
                    </div>
                </div>
            </div>
        </div>
    )
}
