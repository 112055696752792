import clsx from 'clsx'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { FocusOn } from 'react-focus-on'

import { LinkButton } from 'src/libs/design-system'
import { LaserfocusLogoIcon } from 'src/libs/icons'
import { InView, IS_SAFARI } from 'src/libs/react-utils'
import { useIsMinWidthLg } from 'src/libs/tailwind-css'
import { isAppHydratedRef } from 'src/libs/utils'

import { NavBarButtonLevel1, NavBarLinkLevel1 } from './nav-bar-link/nav-bar-link-level-1'
import { MobileMenu, MOBILE_MENU_ID } from './mobile-menu'
import { DesktopMenu } from './desktop-menu'

interface NavBarProps {
    isFixed?: boolean
}

export function NavBar({ isFixed }: NavBarProps) {
    const [isSticking, setIsSticking] = useState(() =>
        isAppHydratedRef.current ? window.scrollY !== 0 : false
    )
    const [menuState, setMenuState] = useMenuState()

    const isMenuOpen = menuState !== 'closed'
    const isMobileMenuOpen = menuState === 'mobile'
    const isDesktopMenuOpen = isMenuOpen && !isMobileMenuOpen
    const shouldBeBlurred = !isFixed || isSticking || isMenuOpen
    const shouldDelayBlur = shouldBeBlurred && !isMenuOpen

    return (
        <>
            {isFixed && (
                <InView onIntersect={({ isIntersecting }) => setIsSticking(!isIntersecting)} />
            )}

            <FocusOn
                as="nav"
                enabled={isMenuOpen}
                onEscapeKey={() => setMenuState('closed')}
                className={clsx(
                    'top-0 w-full z-10 fixed',
                    // will-change-contents: Prevents "laserfocus" Link to flicker during backdrop-filter animation
                    isFixed && 'transition duration-500 will-change-contents',
                    shouldBeBlurred && 'bg-white/80 backdrop-blur-[0.3rem]',
                    shouldDelayBlur && 'delay-300'
                )}
            >
                <div
                    className={clsx(
                        'transition-[min-height] duration-500 lg:duration-150 relative',
                        isMenuOpen ? 'min-h-screen' : 'min-h-[2.9rem] md:min-h-[3.9rem]'
                    )}
                    onClick={isDesktopMenuOpen ? () => setMenuState('closed') : undefined}
                >
                    <div className="px-[0.6rem] py-2 md:px-8 md:py-4 grid md:grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] items-center z-10 relative">
                        <div className="pr-2 grid justify-start">
                            <Link href="/">
                                <a className="inline-grid gap-2 grid-flow-col justify-start items-center font-serif font-bold text-sm leading-none rounded-[0.7rem] p-1 -m-1">
                                    <LaserfocusLogoIcon
                                        usageId="nav-bar"
                                        className="h-[1.2rem] w-[1.2rem]"
                                    />
                                    laserfocus
                                </a>
                            </Link>
                        </div>
                        <div className="hidden lg:grid grid-flow-col gap-2">
                            <NavBarButtonLevel1
                                isOpen={menuState === 'product'}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    setMenuState((v) => (v === 'product' ? 'closed' : 'product'))
                                }}
                            >
                                Product
                            </NavBarButtonLevel1>
                            <NavBarLinkLevel1 href="/pricing">Pricing</NavBarLinkLevel1>
                            <NavBarLinkLevel1 href="/blog">Blog</NavBarLinkLevel1>
                        </div>
                        <div className="ml-auto pl-2 col-start-3 grid grid-flow-col gap-1 md:gap-2">
                            <NavBarLinkLevel1
                                href="https://app.laserfocus.io"
                                className="hidden lg:block"
                            >
                                Log in
                            </NavBarLinkLevel1>
                            <LinkButton
                                variant="primary"
                                className={clsx(
                                    'transition-transform duration-500',
                                    isMobileMenuOpen ? '-translate-y-16' : 'translate-y-0'
                                )}
                                tabIndex={isMobileMenuOpen ? -1 : undefined}
                                href="https://app.laserfocus.io/signup"
                            >
                                Try for free
                            </LinkButton>

                            <MenuButton
                                isMenuOpen={isMobileMenuOpen}
                                onClick={() =>
                                    setMenuState((v) => (v === 'mobile' ? 'closed' : 'mobile'))
                                }
                            />
                        </div>
                    </div>
                    <MobileMenu isOpen={isMobileMenuOpen} />
                    <DesktopMenu menuState={menuState} />
                </div>
            </FocusOn>

            <div className={isFixed ? undefined : 'h-12 md:h-16'} />
        </>
    )
}

type MenuState = 'closed' | 'mobile' | 'product'

function useMenuState() {
    const [menuState, setMenuState] = useState<MenuState>('closed')
    const isMinWidthLg = useIsMinWidthLg()
    const isInitialEffectRef = useRef(true)

    // Close menu whenever width changes from using mobile to destop menu and vice versa
    useEffect(() => {
        if (isInitialEffectRef.current) {
            isInitialEffectRef.current = false
            return
        }

        setMenuState('closed')
    }, [isMinWidthLg])

    // Safari shows focus ring on Laserfocus link in NavBar when we open the menu because of OnFocus. This effect here removes that focus ring.
    useEffect(() => {
        if (IS_SAFARI && menuState !== 'closed') {
            window.setTimeout(() => (document.activeElement as HTMLElement).blur(), 16)
        }
    }, [menuState])

    return [menuState, setMenuState] as const
}

interface MenuButtonProps {
    isMenuOpen: boolean
    onClick(): void
}

function MenuButton({ isMenuOpen, onClick }: MenuButtonProps) {
    const sharedClassNames =
        /* tw: */ 'w-[14px] h-[2px] bg-grey-700 group-hover:bg-blue-500 rounded-full col-start-1 row-start-1 transition'

    return (
        <button
            type="button"
            aria-expanded={isMenuOpen}
            aria-controls={isMenuOpen ? MOBILE_MENU_ID : undefined}
            className="group w-[1.9444rem] h-[1.9444rem] rounded-[0.7rem] hover:bg-blue-100 grid place-content-center gap-[4px] transition-colors lg:hidden"
            onClick={onClick}
        >
            <div
                className={clsx(
                    sharedClassNames,
                    isMenuOpen ? 'rotate-45' : 'translate-y-[-0.1875rem]'
                )}
            />
            <div
                className={clsx(
                    sharedClassNames,
                    isMenuOpen ? '-rotate-45' : 'translate-y-[0.1875rem]'
                )}
            />
        </button>
    )
}
